<template>
  <v-form ref="formElem">
    <TranslationLocales />

    <v-card>
      <v-card-text>
        <v-select
          v-model="form.team_id"
          label="Team"
          item-text="title"
          item-value="value"
          outlined
          :items="teamOptions"
        ></v-select>

        <v-select
          v-model="form.position"
          label="Message Display position"
          item-text="title"
          item-value="value"
          outlined
          :items="positionOptios"
        ></v-select>

        <translatable-editor
          v-model="form.content"
          label="Content"
          outlined
        />

        <h3 class="mt-2">
          From
        </h3>

        <v-date-picker v-model="form.started_at" />

        <v-divider class="mt-2"></v-divider>

        <h3 class="mt-2">
          To
        </h3>

        <v-date-picker v-model="form.expires_at" />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableEditor, TranslationLocales } from '@/components'
import { useTeam, useTranslatable } from '@/composables'
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslatableEditor, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      ...field('team_id', null),
      ...field('content', useTranslatable('content')),
      ...field('position', ''),
      ...field('started_at', ''),
      ...field('expires_at', ''),

      // ...field('active', false),
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const positionOptios = ref([{ title: 'Cart Coupon', value: 'cart_coupon' }])

    const { teamOptions } = useTeam()

    return {
      form,
      formElem,
      validate,
      required,

      positionOptios,
      teamOptions,
    }
  },
}
</script>
