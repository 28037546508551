import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { fetchTeamMessages } from '@api/distributor/team'
import { ref, watch } from '@vue/composition-api'

const cached = []

export default function useList() {
  const tableList = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Team', value: 'team_id' },
    { text: 'Position', value: 'position' },
    { text: 'Content', value: 'content' },
    { text: 'FROM', value: 'started_at' },
    { text: 'TO', value: 'expires_at' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const emailQuery = ref(cached[2] || '')
  const tableTotal = ref(0)
  const loading = ref(false)
  const tableSelectedData = ref([])

  const loadTeamMessages = () =>
    fetchTeamMessages(
      useTableOptions(options.value, {
        search: searchQuery.value,
        email: emailQuery.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        tableList.value = records
        tableTotal.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const deleteTeamMessage = message =>
    deleteTeamMessage(message.id)
      .then(() => {
        useNotifySuccess({ content: 'Delete Success' })
        loadTeamMessages()
      })
      .catch(useNotifyErrors)

  const cachable = [options, searchQuery, emailQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    tableSelectedData.value = []
    loadTeamMessages()
    cache()
  })

  return {
    tableColumns,
    searchQuery,
    emailQuery,
    options,
    tableList,
    tableTotal,
    loading,
    tableSelectedData,
    loadTeamMessages,
    deleteTeamMessage,
  }
}
