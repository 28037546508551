<template>
  <div>
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="loadTeamMessages"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        v-model="tableSelectedData"
        :headers="tableColumns"
        :items="tableList"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>
        <!-- content -->
        <template #[`item.content`]="{item}">
          <div v-html="t(item.content)"></div>
        </template>

        <!-- team -->
        <template #[`item.team_id`]="{item}">
          <span
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'team-view', params: { id: item.id } }"
          >
            {{ item.team.nicename }} ({{ item.team.distributor.slug }})
          </span>
        </template>

        <!-- from -->
        <template #[`item.started_at`]="{item}">
          <span class="text-no-wrap">{{ formatDate(item.started_at) }}</span>
        </template>

        <!-- to -->
        <template #[`item.expires_at`]="{item}">
          <span class="text-no-wrap">{{ formatDate(item.expires_at) }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="deleteTeamMessage(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <team-message-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="loadTeamMessages(); aside = false"
    ></team-message-aside>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import {
mdiDeleteOutline, mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

import TeamMessageAside from '../components/team-message/TeamMessageAside.vue'
import useTeamMessageList from '../composables/useTeamMessageList'

export default {
  components: { TeamMessageAside },

  setup() {
    const {
      tableList, tableColumns, tableTotal, tableSelectedData, searchQuery, emailQuery, options, loadTeamMessages, deleteTeamMessage, loading,
    } = useTeamMessageList()

    const actions = ['Delete', 'Edit', 'Send']

    const selectedAction = ref('')

    const aside = ref(false)
    const resource = ref(null)
    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      tableColumns,
      tableList,
      tableSelectedData,
      tableTotal,

      searchQuery,
      emailQuery,

      loadTeamMessages,
      deleteTeamMessage,
      setAside,
      aside,
      resource,

      options,
      loading,
      actions,
      selectedAction,

      t,
      formatDate,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },
      singleSelect,
      headerprops,
      showFilter,
    }
  },
}
</script>

  <style lang="scss" scoped>
</style>
../../composables/useTeamMessageList
