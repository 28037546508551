import axios from '@axios'

const fetchTeams = queryParams => axios.get('/admin/teams', { params: queryParams })

const storeTeam = data => axios.post('/admin/teams', data)

const updateTeam = (id, data) => axios.put(`/admin/teams/${id}`, data)

const deleteTeam = id => axios.delete(`/admin/teams/${id}`)

const fetchTeamUsage = id => axios.get(`/admin/teams/${id}/usage`)

const fetchTeamMessages = queryParams => axios.get('/admin/team-messages', { params: queryParams })

const storeTeamMessage = data => axios.post('/admin/team-messages', data)

const updateTeamMessage = (id, data) => axios.put(`/admin/team-messages/${id}`, data)

const deleteTeamMessage = id => axios.delete(`/admin/team-messages/${id}`)

export { deleteTeam, deleteTeamMessage, fetchTeamMessages, fetchTeamUsage, fetchTeams, storeTeam, storeTeamMessage, updateTeam, updateTeamMessage }
